
import './App.css';
import {  Routes, Route, BrowserRouter } from 'react-router-dom';
import Apps from '../Apps';
import Signin from '../Signin';
import NewApp from '../NewApp';
import Callback from '../Callback/Callback';
import Home from '../Home';
import { useEffect } from 'react';
import { LOCAL_STORAGE_KEYS, getItemPersistent, setItemPersistent } from '../../util/cache';
function App() {
  useEffect(() => {
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_HOST}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_ROOT_PATH}`);

    // console.log(`${SESSION_STORAGE_KEYS.DEMO_DEFAULT_ENV}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_API}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_REDIRECT_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_ID}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_CLIENT_SECRET}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_LOGIN_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_REGISTER_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_GRANT_TYPE}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_USER_INFO}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_OIDC_TOKEN_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_LOGOUT_URI}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_ACCESS_TOKEN}`);
    // console.log(`${SESSION_STORAGE_KEYS.DEMO_ENV_ID_TOKEN}`);

    console.log(`app version: ${getItemPersistent(LOCAL_STORAGE_KEYS.VERSION)}`);

  }, []);

  return (
    <BrowserRouter>
      <Routes>
      <Route path="callback" element={<Callback />}></Route>
        <Route path="home" element={<Home />}></Route>
        <Route path="defaultapps" element={<Apps />}></Route>
        <Route path="new" element={<NewApp />}></Route>
        <Route path="/" element={<Signin />}></Route>
        <Route path="*" element={<Signin />}></Route>
        {/* <Route path="*" element={<Signin />} /> */}
        {/* <Route path="/" element={<Navigate to={'/'} replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
